import { isDevelopment } from '../config/config';
import { logEvent } from '../config/firebase';

// Log the error to Firebase Analytics
const logErrorToAnalytics = (error, errorInfo, addtionalLog = {}) => {
  if(isDevelopment){
    console.error(error, errorInfo);
    return
  };
  logEvent('exception', {
    description: error.message || 'Unknown error',
    component_stack: errorInfo ? errorInfo.componentStack || errorInfo.source : '',
    ...addtionalLog
  });
};

export {logErrorToAnalytics};
